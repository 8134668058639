.btn.client-button {
  background-color: #77bc43;
  color: #fff;
}

.btn.client-button:hover {
  background-color: #2b5134;
  color: #fff;
}

.navbar {
  /* background-color: #495c67; */
  background-color: #f7f7f7;
}

.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.form-signin .signin-logo {
  /* height: 300px; */
  /* width: 300px; */
}

.form-signin .signin-wrap {
  display: flex;
  justify-content: space-around;
}

.login-powered {
  display: flex;
  font-size: 14px;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.login-powered .text {
  font-size: 14px;
}
.login-powered img{
  height: 20px;
  margin-top: 3px;
  margin-left: 4px;
}

.login-form {
  padding-top: 50px;
}